
.logo {
    width: var(--logo-width);
    height: var(--logo-height);
    -webkit-transition: all ease-out .2s; -ms-transition: all ease-out .2s; transition: all ease-out .2s;
    a {
        display: block;
        width: 100%;
        height: 100%;
        background-image: url(/images/logo-dark2.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &--light {
        a {
            background-image: url(/images/logo2.svg);
        }
    }
    &--dark {
        a {
            background-image: url(/images/logo-dark2.svg);
        }
    }
}
.header {
    .logo {
        width: var(--header-logo-width);
        height: var(--header-logo-height);
    }
}
